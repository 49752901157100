import {Suspense} from 'react'
import {useRoutes} from 'react-router-dom'

import {AppProviders} from '~/context'
import NotFound from '~/routes/404'
import Callback from '~/routes/callback'
import {ResetMFA} from '~/routes/mfa.reset'
import {ResetMFAComplete} from '~/routes/mfa.reset-complete'
import * as Playground from '~/routes/playground'
import * as ProfileRoute from '~/routes/profile'
import Signup from '~/routes/signup'

export const App = () => {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  )
}

export const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/playground',
      element: (
        <Suspense fallback={<Playground.LoadingState />}>
          <Playground.RouteComponent />
        </Suspense>
      ),
    },
    {
      path: '/profile',
      element: (
        <Suspense fallback={<ProfileRoute.LoadingState />}>
          <ProfileRoute.RouteComponent />,
        </Suspense>
      ),
    },
    {
      path: '/signup-callback',
      element: (
        <Callback
          assetType="dataroom"
          callbackType="signup"
          queryKeyCallback="state"
        />
      ),
    },
    {
      path: '/login-callback',
      element: <Callback assetType="dataroom" callbackType="login" />,
    },
    {
      path: '/logout-callback',
      element: (
        <Callback
          assetType="dataroom"
          callbackType="logout"
          queryKeyRedirect="redirectAfterLogout"
        />
      ),
    },
    {
      path: '/mfa/reset',
      element: <ResetMFA />,
    },
    {
      path: '/reset-mfa',
      element: <ResetMFAComplete />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  return element
}
