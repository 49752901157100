import type {DocumentNode} from 'graphql'
import {buildASTSchema, parse} from 'graphql'

export const parseSchema = (schema?: string | DocumentNode) => {
  if (!schema) return null

  let doc: DocumentNode
  if (typeof schema === 'string') {
    doc = parse(schema)
  } else {
    doc = schema
  }

  try {
    const ast = buildASTSchema(doc, {
      assumeValid: true,
      assumeValidSDL: true,
    })

    return ast
  } catch {
    return null
  }
}
