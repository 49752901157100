import {gql, useSuspenseQuery} from '@apollo/client'
import {withAuthenticationRequired} from '@auth0/auth0-react'
import '@graphiql/plugin-explorer/dist/style.css'
import Box from '@mui/material/Box'
import 'graphiql/graphiql.css'
import React from 'react'

import {Header} from '~/components/header'
import {Playground} from '~/components/playground'
import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import type {PlaygroundRouteQuery} from '~/types/api'

import './playground.theme.css'

const PLAYGROUND_ROUTE_QUERY = gql`
  query PlaygroundRouteQuery {
    me {
      firstName
      lastName
    }
  }
`

export const RouteComponent = withAuthenticationRequired(
  () => {
    const {data} = useSuspenseQuery<PlaygroundRouteQuery>(
      PLAYGROUND_ROUTE_QUERY,
    )
    const profileInfo = data.me

    if (!profileInfo) {
      throw new Error('Must be logged in')
    }
    const userName = `${profileInfo.firstName} ${profileInfo.lastName}`

    return (
      <Box height="calc(100vh - 54px)">
        <Header userName={userName} productName="Playground" />
        <Playground />
      </Box>
    )
  },
  {
    onRedirecting: () => <MessageSection title={<Progress />} />,
  },
)

export const LoadingState = () => {
  return <MessageSection title={<Progress />} />
}
