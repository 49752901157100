import {CssBaseline, Link, ThemeProvider, Typography} from '@mui/material'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import * as React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {BrowserRouter as Router} from 'react-router-dom'

import {AnsaradaLight} from '@ansarada/mui-themes/lib/ansarada'

import {MessageSection} from '~/components/sections/message-section'
import {queryClient} from '~/lib/react-query'

import {ApolloClientProvider} from './apollo-context'
import {AuthProvider} from './auth-context'

type AuthProviderProps = {
  children: React.ReactNode
}

function FullPageErrorFallback({error}: {error: Error}) {
  const ContactSupportLink = (
    <Link
      underline="hover"
      fontWeight="bold"
      href="https://www.ansarada.com/contact-support"
    >
      contact us
    </Link>
  )

  return (
    <MessageSection title="Unknown Error" subtitle={error.message}>
      <Typography component="p" variant="body2" data-test-id="error">
        Please {ContactSupportLink} for more information!
      </Typography>
    </MessageSection>
  )
}

function AppProviders({children}: AuthProviderProps) {
  return (
    <ThemeProvider theme={AnsaradaLight}>
      <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthProvider>
              <ApolloClientProvider>{children}</ApolloClientProvider>
            </AuthProvider>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </ErrorBoundary>
      <CssBaseline />
    </ThemeProvider>
  )
}

export {AppProviders}
